
import Alaska from './states/Alaska'
import Alabama from './states/Alabama'
import Arkansas from './states/Arkansas'
import Arizona from './states/Arizona'
import California from './states/California'
import Colorado from './states/Colorado'
import Connecticut from './states/Connecticut'
import Delaware from './states/Delaware'
import Florida from './states/Florida'
import Georgia from './states/Georgia'
import Hawaii from './states/Hawaii'
import Idaho from './states/Idaho'
import Illinois from './states/Illinois'
import Indiana from './states/Indiana'
import Iowa from './states/Iowa'
import Kansas from './states/Kansas'
import Kentucky from './states/Kentucky'
import Louisiana from './states/Louisiana'
import Maine from './states/Maine'
import Maryland from './states/Maryland'
import Massachusetts from './states/Massachusetts'
import Michigan from './states/Michigan'
import Minnesota from './states/Minnesota'
import Missouri from './states/Missouri'
import Mississippi from './states/Mississippi'
import Montana from './states/Montana'
import Nebraska from './states/Nebraska'
import Nevada from './states/Nevada'
import NewHampshire from './states/NewHampshire'
import NewJersey from './states/NewJersey'
import NewMexico from './states/NewMexico'
import NewYork from './states/NewYork'
import NorthCarolina from './states/NorthCarolina'
import NorthDakota from './states/NorthDakota'
import Ohio from './states/Ohio'
import Oklahoma from './states/Oklahoma'
import Oregon from './states/Oregon'
import Pennsylvania from './states/Pennsylvania'
import RhodeIsland from './states/RhodeIsland'
import SouthCarolina from './states/SouthCarolina'
import SouthDakota from './states/SouthDakota'
import Tennessee from './states/Tennessee'
import Texas from './states/Texas'
import Utah from './states/Utah'
import Vermont from './states/Vermont'
import Virginia from './states/Virginia'
import Washington from './states/Washington'
import WashingtonDC from './states/WashingtonDC'
import WestVirginia from './states/WestVirginia'
import Wisconsin from './states/Wisconsin'
import Wyoming from './states/Wyoming'

const stateParams = [
  {component: Alabama, abbreviation: 'AL', votes: 9},
  {component: Alaska, abbreviation: 'AK', votes: 3},
  {component: Arkansas, abbreviation: 'AR', votes: 6},
  {component: Arizona, abbreviation: 'AZ', votes: 11},
  {component: California, abbreviation: 'CA', votes: 55},
  {component: Colorado, abbreviation: 'CO', votes: 9},
  {component: Connecticut, abbreviation: 'CT', votes: 7},
  {component: Delaware, abbreviation: 'DE', votes: 3},
  {component: Florida, abbreviation: 'FL', votes: 29},
  {component: Georgia, abbreviation: 'GA', votes: 16},
  {component: Hawaii, abbreviation: 'HI', votes: 4},
  {component: Idaho, abbreviation: 'ID', votes: 4},
  {component: Illinois, abbreviation: 'IL', votes: 20},
  {component: Indiana, abbreviation: 'IN', votes: 11},
  {component: Iowa, abbreviation: 'IA', votes: 6},
  {component: Kansas, abbreviation: 'KS', votes: 6},
  {component: Kentucky, abbreviation: 'KY', votes: 8},
  {component: Louisiana, abbreviation: 'LA', votes: 8},
  {component: Maine, abbreviation: 'ME', votes: 4, byDistrict: true},
  {component: Maryland, abbreviation: 'MD', votes: 10},
  {component: Massachusetts, abbreviation: 'MA', votes: 11},
  {component: Michigan, abbreviation: 'MI', votes: 16},
  {component: Minnesota, abbreviation: 'MN', votes: 10},
  {component: Mississippi, abbreviation: 'MS', votes: 6},
  {component: Missouri, abbreviation: 'MO', votes: 10},
  {component: Montana, abbreviation: 'MT', votes: 3},
  {component: Nebraska, abbreviation: "NE", votes: 5, byDistrict: true},
  {component: Nevada, abbreviation: 'NV', votes: 6},
  {component: NewHampshire, abbreviation: 'NH', votes: 4},
  {component: NewJersey, abbreviation: 'NJ', votes: 14},
  {component: NewMexico, abbreviation: 'NM', votes: 5},
  {component: NewYork, abbreviation: 'NY', votes: 29},
  {component: NorthCarolina, abbreviation: 'NC', votes: 15},
  {component: NorthDakota, abbreviation: 'ND', votes: 3},
  {component: Ohio, abbreviation: 'OH', votes: 18},
  {component: Oklahoma, abbreviation: 'OK', votes: 7},
  {component: Oregon, abbreviation: 'OR', votes: 7},
  {component: Pennsylvania, abbreviation: 'PA', votes: 20},
  {component: RhodeIsland, abbreviation: 'RI', votes: 4},
  {component: SouthCarolina, abbreviation: 'SC', votes: 9},
  {component: SouthDakota, abbreviation: 'SD', votes: 3},
  {component: Tennessee, abbreviation: 'TN', votes: 11},
  {component: Texas, abbreviation: 'TX', votes: 38},
  {component: Utah, abbreviation: 'UT', votes: 6},
  {component: Vermont, abbreviation: 'VT', votes: 3},
  {component: Virginia, abbreviation: 'VA', votes: 13},
  {component: Washington, abbreviation: 'WA', votes: 12},
  {component: WestVirginia, abbreviation: 'WV', votes: 5},
  {component: Wisconsin, abbreviation: 'WI', votes: 10},
  {component: Wyoming, abbreviation: 'WY', votes: 3},
  {component: WashingtonDC, abbreviation: 'DC', votes: 3},
];

const initialElectoralCollege = Object({})
for (let params of stateParams) {
  const votes = Object({D: 0, R: 0})
  initialElectoralCollege[params.abbreviation] = votes
}

export { stateParams, initialElectoralCollege };
