import React from 'react'

function WashingtonDC({handleClick, className}) {
  return (<g id="DC">
    <title>District of Columbia</title>
    <path className={className} id="DC1" d="M801.8 253.8l-1.1-1.6-1-.8 1.1-1.6 2.2 1.5z"/>
    <circle onClick={handleClick} id="DC2" stroke="#FFFFFF" strokeWidth="1.5" cx="801.3" cy="251.8" r="5" opacity="1"/>
  </g>);
}

export default WashingtonDC
