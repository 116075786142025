import React, {useState} from 'react';
import './App.css';

import {stateParams} from './States'
import {initialElectoralCollege} from './States'

function Totals({D, R}) {
  let message = `Democrats: ${D} Republicans ${R}`
  if (D >= 270) {
    message = `Biden/Harris ticket wins with ${D} electoral college votes!`
  } else if (R >= 270) {
    message = `Trump/Pence ticket wins with ${R} electoral college votes.`
  }
  return <h1>{message}</h1>
}

function Summary({electoralCollege}) {
  const states = []
  for (let state in electoralCollege) {
     states.push(state)
  }
  let demStates = [], repStates = []
  for (let state of states) {
    const result = electoralCollege[state]
    const D = result.D
    const R = result.R
    if (D) {
      demStates.push(`${state} (${D})`)
    }

    if (R) {
      repStates.push(`${state} (${R})`)
    }
  }

  const summary = []
  if (demStates.length)
    summary.push(<li key="D"><b>D:</b> {demStates.join(', ')}</li>)
  if (repStates.length)
    summary.push(<li key="R"><b>R:</b> {repStates.join(', ')}</li>)

  return (
    <ul>
    {summary}
    </ul>
  );
}

function downloadImage(e) {
  e.preventDefault()
  var canvas = document.getElementById('output');
  var ctx = canvas.getContext('2d');
  var data = new XMLSerializer().serializeToString(document.getElementById('map'))

  var DOMURL = window.URL || window.webkitURL || window;

  var img = new Image(959, 593);
  var svg = new Blob([data], {type: 'image/svg+xml;charset=utf-8'});
  let svgSize = document.getElementById('map').getBoundingClientRect();
  canvas.width = svgSize.width
  canvas.height = svgSize.height
  var url = DOMURL.createObjectURL(svg);

  img.onload = function () {
    ctx.drawImage(img, 0, 0);
    DOMURL.revokeObjectURL(url);
    var imgURI = canvas.toDataURL("image/png").replace('image/png', 'image/octet-stream');
  var evt = new MouseEvent('click', {
    view: window,
    bubbles: false,
    cancelable: true
  });

  var a = document.createElement('a');
  a.setAttribute('download', 'ElectoralCollege.png');
  a.setAttribute('href', imgURI);
  a.setAttribute('target', '_blank');

  a.dispatchEvent(evt);
  }

  img.src = url;
}

function useLocalStorage(key, initial) {
  const [value, setValue] = useState(initial)
  const serialize = (v) => {
    localStorage.setItem(key, JSON.stringify(v))
    setValue(v)
  };
  const stored = localStorage.getItem(key)
  if (stored)
    return [JSON.parse(stored), serialize]
  return [value, serialize]
}

function getClassName(votes) {
  if (votes.D && votes.R)
    return 'split'
  if (votes.D)
    return 'democratic'
  if (votes.R)
    return 'republican'
  return ''
}

function Map() {
  const [electoralCollege, setElectoralCollege] = useLocalStorage('ec', initialElectoralCollege)

  let D = 0, R = 0
  for (let state in electoralCollege) {
    D += electoralCollege[state].D
    R += electoralCollege[state].R
  }

  function createHandler(state, votes, byDistrict=false) {
    function handleClick(e) {
      const newElectoralCollege = {...electoralCollege}

      const result = newElectoralCollege[state]
      let D = result.D, R = result.R
      if (!D && !R) {
        result.D = votes
      } else if (!D && R) {
        result.D = 0
        result.R = 0
      } else if (byDistrict) {
        result.R = result.R + 1
        result.D = votes - result.R
        if (result.R === result.D) {
          result.R = result.R + 1
          result.D = votes - result.R
        }
      } else {
        result.R = votes
        result.D = 0
      }
      newElectoralCollege[state] = result
      setElectoralCollege(newElectoralCollege)
    }
    return handleClick
  }
    const stateComponents = []
    for (let params of stateParams) {
      stateComponents.push(React.createElement(params.component, {key: params.abbreviation, handleClick: createHandler(params.abbreviation, params.votes), className: getClassName(electoralCollege[params.abbreviation])}))
    }
    

  return (
    <div id="dashboard">
    <div id="map-display">
    <svg id="map" height="593" width="959" preserveAspectRatio="xMinYMin" xmlns="http://www.w3.org/2000/svg" stroke="paperwhite" fill="silver">
<rect width="100%" height="100%" fill="white"/>
<g xmlns="http://www.w3.org/2000/svg" className="state">
  {stateComponents}
</g>
      <path xmlns="http://www.w3.org/2000/svg" id="frames" fill="none" stroke="silver" strokeWidth="2" d="M215 493v55l36 45M0 425h147l68 68h85l54 54v46"/>
  </svg>
    <button id="download-button" onClick={downloadImage}>Download Map</button>
  <canvas id="output" style={{display: 'none'}} />
  </div>
    <div id="results">
      <Totals D={D} R={R} />
      <Summary electoralCollege={electoralCollege} />
    </div>
  </div>);
}

function App() {
  return <Map />
}

export default App;
